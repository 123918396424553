.data-import-table {
  min-height: 400px;
  max-height: 500px;
  font-size: 1em;
  overflow-x: auto;
  overflow-y: auto;
}

td.centered {
  text-align: center !important;
}

/* This code is from hover action */
.collapsing.upload-log-entry.hoverRow:hover{
  cursor: pointer;
  background-color: #EBEBEB !important;
}

.collapsing.upload-log-entry.hoverRow.active{
  background-color: #EBEBEB !important;
}

.collapsing.upload-log-entry.hoverRow.selectRow{
  background-color: #EBEBEB;
}

.td-class-column.edit, .td-class-column.delete{
  padding: 2% 3% !important;
}